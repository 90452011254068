.panel-wrapper {
    margin-bottom: 24px;
}

.panel-primary {}

.panel-secondary {
    margin-top: 24px;
}

.panel-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}