.App {
  margin: auto;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content-padding {
  padding: 20px 20px 0 20px;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
  }

  .content {
    padding-top: 0;
  }
}

.padding-left-16 {
  padding-left: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.active-table-item {
  background-color: #e0e0e0;
}